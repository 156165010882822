import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1.`}</h2>
    <p>{`A car dashboard is an information tool like that.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/djo1nckex/image/upload/zero260_media/5fOi8z2giZV1axz5FJ9lgm/14baaac3ad31ad7eaf256edd3517499d/coral-winter-1954-pontiac-star-chief-custom-catalina-hardtop008.jpg",
        "alt": "Pontiac Chieftan steering wheel"
      }}></img></p>
    <h2>{`2.`}</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/xhBPaO0IMSg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`DSKY `}</p>
    <p><img parentName="p" {...{
        "src": "https://hackaday.com/wp-content/uploads/2013/09/apollo-navigation-system.png",
        "alt": "apollo sextant"
      }}></img></p>
    <p>{`Or see James Burke explaining dials and knobs to the UK public for the BBC, inviting them to "take a look around the command module".`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/NTLBBV8zswA?start=483" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/j2-mrQDJ_Sk?start=788" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p><img parentName="p" {...{
        "src": "https://hips.hearstapps.com/hmg-prod/images/f1-wheel-steering-wheel-1552593550.png",
        "alt": "f1 wheel"
      }}></img></p>
    <p>{`Asked to generate an objective function for the design of an F1 wheel without further context, GPT4 offers: `}</p>
    <p><inlineCode parentName="p">{`score = B + W + T + G + V + Tc + E + D + C`}</inlineCode></p>
    <p>{`Where: `}</p>
    <ul>
      <li parentName="ul">{`B: Button placement score`}</li>
      <li parentName="ul">{`W: Wheel size score`}</li>
      <li parentName="ul">{`T: Thumb reach score`}</li>
      <li parentName="ul">{`G: Grip score`}</li>
      <li parentName="ul">{`V: Vibration control score`}</li>
      <li parentName="ul">{`Tc: Turbocharge control score`}</li>
      <li parentName="ul">{`E: Energy recovery system control score`}</li>
      <li parentName="ul">{`D: Display visibility score`}</li>
      <li parentName="ul">{`C: Communication system score`}</li>
    </ul>
    <p>{`demo of a tooltip, with context specific action inside modal`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/239697822-bd3d7b7c-7a27-4295-90e4-3715de67c5dc.png",
        "alt": "moon.js"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/239698982-cc922599-1ed7-4e95-b94d-4c6618e1f830.png",
        "alt": "tooltip in modal on iss monitor"
      }}></img></p>
    <p>{`"measurement elements and controls"`}</p>
    <p>{`Draper lab `}</p>
    <p>{`Machine aided cognition `}</p>
    <p><a parentName="p" {...{
        "href": "https://web.archive.org/web/20210721222643/https://wehackthemoon.com/tech/apollo-guidance-computer-agc-computer-engineering-breakthrough"
      }}>{`https://web.archive.org/web/20210721222643/https://wehackthemoon.com/tech/apollo-guidance-computer-agc-computer-engineering-breakthrough`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://web.archive.org/web/20210623085843/https://wehackthemoon.com/tech/amazing-dsky-leapfrog-computer-science"
      }}>{`https://web.archive.org/web/20210623085843/https://wehackthemoon.com/tech/amazing-dsky-leapfrog-computer-science`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://web.archive.org/web/20210927035140/https://wehackthemoon.com/tech/inertial-measurement-unit-mechanical-engineering-wizardry"
      }}>{`https://web.archive.org/web/20210927035140/https://wehackthemoon.com/tech/inertial-measurement-unit-mechanical-engineering-wizardry`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://web.archive.org/web/20211021024058/https://wehackthemoon.com/tech/space-sextant-navigates-moon-missions"
      }}>{`https://web.archive.org/web/20211021024058/https://wehackthemoon.com/tech/space-sextant-navigates-moon-missions`}</a></p>
    <h2>{`3.`}</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/B1J2RMorJXM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h2>{`4.`}</h2>
    <blockquote className="twitter-tweet">
    <p lang="en" dir="ltr">Cosmonauts’ information display system. This console gives the Soyuz module’s crew information on the spacecraft’s condition and trajectory. It provides a keyboard interface with the spacecraft’s computer for inputing commands during manoeuvering, docking, descent and landing. 
    <a href="https://t.co/zjoucGYesq">pic.twitter.com/zjoucGYesq</a></p>&mdash; World of Engineering (@engineers_feed) 
    <a href="https://twitter.com/engineers_feed/status/1315523404775280642?ref_src=twsrc%5Etfw">October 12, 2020</a>
    </blockquote> 
    <h2>{`5.  SpaceX`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=P06X2TZUKZU&ab_channel=Knowledge%40NASA"
      }}>{`collaborative design approach`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=SMLDAgDNOhk&ab_channel=Knowledge%40NASA"
      }}>{`rapid prototyping`}</a></p>
    <p>{`Is it a natural choice? After all, they want to push real time updates, so the application will be networked, and if they want to push code, the astronauts can just refresh with one touch. There's a joke about JavaScript "lacking gravity" in here, somewhere.`}</p>
    <h2>{`6. Pareto`}</h2>
    <p><a parentName="p" {...{
        "href": "https://onlinelibrary.wiley.com/doi/full/10.1111/cgf.13990"
      }}>{`visualization of pareto frontier itself`}</a></p>
    <p>{`Evolutionary design is different on the web... but we do think about it in terms of feedback, how much feedback, how long the feedback loops are`}</p>
    <h2>{`7. airbus a380 cockpit`}</h2>
    <p><a parentName="p" {...{
        "href": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Airbus_A380_cockpit.jpg"
      }}>{`https://upload.wikimedia.org/wikipedia/commons/a/a4/Airbus_A380_cockpit.jpg`}</a></p>
    <h2>{`8. nuclear control`}</h2>
    <p>{`Of course, the Apollo mission control isn't the only large scale facility from this era with room sized dedicated displays for different tasks.`}</p>
    <p>{`Look at how much floor space they have in the nuclear reactor room. They're pacing around! `}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/nemYBeT4aQY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`Seriously, watch them walk around the interfaces! `}</p>
    <h2>{`13`}</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ry55--J4_VQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h1>{`14 a tour.`}</h1>
    <p>{`Note the integration of "symptom based flow charts" into the operating environment of the nuclear plant`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ldG3OjdZqJw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h1>{`15`}</h1>
    <p>{`Frequently there aren't a lot of people with these jobs. There aren't a lot of destroyer or fighter pilot captains. But decisions by each might change the course of a war. There aren't a lot of biologists, relative to the population, or for each tool, but the discovery of one might cure a disease. There aren't a lot of music producers, relative to the population, but they might be mixing a record listened to by billions of people.`}</p>
    <p>{`Most "web design" embeds deep patterns regarding the objective function for the consumer web, which are considerations like how well it works on different screen sizes and how accessible it is on a phone, how quickly the person can accomplish a primary workflow of purchasing their items.`}</p>
    <h1>{`16 lead planes`}</h1>
    <p>{`How will we know which kind of design, if the capabilities matter and you can't build an airplane with a 4000 foot wingspan made out of lead, it doesn't matter what you draw, the capabilities matter, you can build a simple consumer website with a checkbox ... it's the engineer who knows capacity and constraints of mediation, and the domain expert who knows tasks and goals and information and decisions. That's all that matters. `}</p>
    <h1>{`17 Katz books`}</h1>
    <p><img parentName="p" {...{
        "src": "https://1.bp.blogspot.com/-f_PdtO-tSNg/UNO5QpFh7KI/AAAAAAAABSg/K_o4KiN8pls/s1600/Screen+shot+2012-12-20+at+8.19.36+PM.png",
        "alt": null
      }}></img></p>
    <h1>{`mother of all demos`}</h1>
    <p>{`Might seem obvious to share `}<a parentName="p" {...{
        "href": "https://invention.si.edu/mother-all-demos"
      }}>{`"Mother of all Demos"`}</a>{` but perhaps you haven't watched the whole thing, or haven't in a while! Or maybe you haven't read Augmenting Human Intellect: A Conceptual Framework in its entirety even though you have it in a tab `}<a parentName="p" {...{
        "href": "https://csis.pace.edu/~marchese/CS835/Lec3/DougEnglebart.pdf"
      }}>{`PDF`}</a>{`. Or .`}</p>
    <p>{`The History of the Sketchpad Computer Program - A Complete Guide`}</p>
    <p><a parentName="p" {...{
        "href": "https://history-computer.com/sketchpad-guide/"
      }}>{`https://history-computer.com/sketchpad-guide/`}</a></p>
    <p>{`Sketchpad
Ivan Sutherland Sketchpad Demo 1963
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=6orsmFndx_o&ab_channel=InteractiveChronicles"
      }}>{`https://www.youtube.com/watch?v=6orsmFndx_o&ab_channel=InteractiveChronicles`}</a></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/6orsmFndx_o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/B6rKUf9DWRI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/SN--t9jXQc0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      